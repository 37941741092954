export const fetchAllData = () => {
    return new Promise((resolve, rejects) => {
        fetch("https://opensheet.elk.sh/1TY2HFTOEclbid-7dQCSxFYpV-K4NF2HWU1tXbY6Gk_8/94fbcc2ab1e24359850fa1870fc988bc", {
            method: 'GET',
            })
            .then((data) => {
                resolve(data);
            },
            (err) => {
                rejects(err);
            }
    );
    })
}